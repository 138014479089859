<script lang="ts" setup>
import type { PropType } from 'vue'
import Tooltip from './Tooltip.vue'
import QuestionMarkOutlined from './icons/figma/QuestionMarkOutlined.vue'

export type TVariant = 'default' | 'gradient' | 'infinite'
type TSize = 'small' | 'medium' | 'large'
type TColor = 'blue' | 'red' | 'green' | 'cyan' | 'purple'

const props = defineProps({
  variant: {
    type: String as PropType<TVariant>,
    default: 'default'
  },
  size: {
    type: String as PropType<TSize>,
    default: 'medium'
  },
  color: {
    type: String as PropType<TColor>,
    default: 'blue'
  },
  labelText: {
    type: String,
    default: 'Progress'
  },
  subLabelText: {
    type: String,
    default: 'sub-label text of the progress bar'
  },
  showSubLabelText: {
    type: Boolean,
    default: false
  },
  percentage: {
    type: Number,
    default: 75
  },
  showPercentage: {
    type: Boolean,
    default: false
  },
  showInfoIcon: {
    type: Boolean,
    default: false
  },
  tooltipText: {
    type: String,
    default: 'This is a progress bar'
  }
})

const progressBarClass = computed(() => {
  return `${
    props.size === 'small' ? 'h-1.5' : props.size === 'large' ? 'h-4' : 'h-2.5'
  } w-full rounded-full bg-gray-30 dark:bg-gray-50 mb-2`
})

const progressBarBodyColor = computed(() => {
  if (props.variant === 'default') {
    switch (props.color) {
      case 'red':
        return 'bg-red-30'
      case 'green':
        return 'bg-green-30'
      case 'cyan':
        return 'bg-cyan-30'
      case 'purple':
        return 'bg-purple-30'
      default:
        return 'bg-blue-30'
    }
  } else {
    switch (props.color) {
      case 'red':
        return 'bg-gradient-to-r from-red-20 to-red-40'
      case 'green':
        return 'bg-gradient-to-r from-green-20 to-green-40'
      case 'cyan':
        return 'bg-gradient-to-r from-cyan-20 to-cyan-40'
      case 'purple':
        return 'bg-gradient-to-r from-purple-20 to-purple-40'
      default:
        return 'bg-gradient-to-r from-blue-20 to-blue-40'
    }
  }
})

const infiniteProgressBarTrackColor = computed(() => {
  switch (props.color) {
    case 'red':
      return 'bg-red-30'
    case 'green':
      return 'bg-green-30'
    case 'cyan':
      return 'bg-cyan-30'
    case 'purple':
      return 'bg-purple-30'
    default:
      return 'bg-blue-30'
  }
})

const progressBarBodyClass = computed(() => {
  return `${
    props.size === 'small' ? 'h-1.5' : props.size === 'large' ? 'h-4' : 'h-2.5'
  } rounded-full ${
    props.variant === 'default' || props.variant === 'gradient' ? progressBarBodyColor.value : ''
  }`
})
</script>

<template>
  <div>
    <div class="mb-2 flex justify-between">
      <div class="flex">
        <div class="text-base text-blue-700 mr-2 font-medium dark:text-white">{{ labelText }}</div>
        <div v-if="showInfoIcon" class="group relative">
          <div class="h-[1.25rem] w-[1.25rem] text-gray-50 hover:text-cyan-40">
            <QuestionMarkOutlined class="fill-current" />
          </div>
          <Tooltip
            :text="tooltipText"
            merged-class="top-1 transition-opacity group-hover:opacity-100 opacity-0"
          ></Tooltip>
        </div>
      </div>
      <div
        v-if="variant !== 'infinite' && showPercentage"
        class="text-sm text-blue-700 font-medium dark:text-white"
      >
        {{ percentage }}%
      </div>
    </div>
    <div v-if="variant === 'default' || variant === 'gradient'" :class="progressBarClass">
      <div :class="progressBarBodyClass" :style="`width: ${percentage}%`"></div>
    </div>
    <div v-else :class="progressBarClass">
      <div :class="progressBarBodyClass">
        <div class="progressBarBodyTrack" :class="infiniteProgressBarTrackColor"></div>
      </div>
    </div>
    <div v-if="showSubLabelText" class="text-base text-blue-700 font-light dark:text-white">
      {{ subLabelText }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.progressBarBodyTrack {
  position: relative;
  height: inherit;
  border-radius: inherit;
  animation: barLoading 2s linear infinite;
}

@keyframes barLoading {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  15% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  85% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}
</style>
