<script setup lang="ts">
const haveViewer = ref(true)

onMounted(() => {
  haveViewer.value = navigator.pdfViewerEnabled || false
})

const props = defineProps({
  url: {
    type: String,
    required: true
  },
  height: {
    type: String,
    required: false,
    default: '600px'
  },
  width: {
    type: String,
    required: false,
    default: '100%'
  },
  downloadText: {
    type: String,
    required: false,
    default: 'Download'
  }
})
</script>

<template>
  <embed
    v-if="haveViewer"
    :src="props.url"
    :height="props.height"
    :width="props.width"
    type="application/pdf"
    scrolling="auto"
  />
  <div v-else>
    <ButtonBlackButton
      mode="windowOpen"
      :text="props.downloadText"
      size="normal"
      variant="outlined"
      :to="props.url"
      width-override="100%"
    />
  </div>
</template>
